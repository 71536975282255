@import url('https://fonts.googleapis.com/css2?family=Space+Mono&display=swap');

* {
    font-family: Space Mono, monospace;
    border-width: 0;
    /* smooth transitions */
    transition: 0.15s cubic-bezier(0.33, 0.55, 0.31, 1),
        backdrop-filter 1s cubic-bezier(0.33, 0.55, 0.31, 1), fill 0s linear;
    font-variant-ligatures: none;
    backface-visibility: hidden;
}

html,
body,
#root {
    /* width: calc(1920px * 5);
    height: calc(1080px * 5); */
    height: 100%;
}

.App {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes slideInFromTop {
    0% {
        top: -150%;
    }
    100% {
        top: 8px;
    }
}

.container {
    position: absolute;
    border-radius: 6px;
    background-color: #41a3cd44;
    color: white;
    text-align: center;
    border: 1px solid #41a3cd55;
    backdrop-filter: blur(15px) saturate(110%) brightness(0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.end-game-alert {
    @extend .container;
    top: 8px;
    min-width: 200px;
    min-height: 70px;
    z-index: 5;
    align-self: center;
    animation: 0.5s slideInFromTop;
    & span {
        font-size: 1.7rem;
        font-weight: 900;
        color: #41a3cd;
    }
}

.counter {
    @extend .container;
    top: 8px;
    left: 8px;
    min-width: 120px;
    min-height: 42px;
    z-index: 2;
    padding: 0 10px;
}

.hamburger {
    @extend .container;
    top: 8px;
    right: 8px;
    width: 42px;
    height: 42px;
    z-index: 5;
    cursor: pointer;
    // & svg {
    //     fill: white;
    // }
}

.hamburger-dropdown {
    @extend .container;
    top: 58px;
    right: 8px;
    min-width: 42px;
    min-height: 42px;
    z-index: 5;
    display: flex;
}

.main-menu {
    @extend .container;
    min-width: 200px;
    min-height: 300px;
    padding: 50px 40px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.diff-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

    & h3 {
        margin-bottom: 10px;
        // small caps
        font-variant-caps: all-small-caps;
        font-weight: 900;
        color: #808f9e;
    }
}

.diff-selector {
    display: grid;
    grid-template-columns: 30px 150px 30px;
    grid-template-areas: 'left diff right';
}

.start-btn {
    @extend .container;
    position: relative;
    padding: 10px 20px;
    font-size: 1.3rem;
    font-weight: 600;
    cursor: pointer;
}

.blur {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 4;
    transition-duration: 2000ms;
}

.zoom-canvas {
    width: 100%;
    height: 100%;
    background-color: #0d151d;
    align-items: center;
    justify-content: center;
    display: flex;
}

.separator-grid {
}

.cell {
    cursor: pointer;
    position: relative;
    justify-content: center;
    align-items: center;
    display: flex;
    margin: -1px;
}
